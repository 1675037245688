import { IconButton, Stack } from "@fluentui/react";
import { Field, Textarea, makeStyles } from "@fluentui/react-components";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

interface InputProps {
    onSend: (message: string) => void;
    isLoading: boolean;
    stopResponding: boolean;
    showChatBot: boolean;
}

const useStyles = makeStyles({
    securityText: {
        color: "#14253C",
        fontSize: "12px",
        fontWeight: 600
    },
    securityIcon: {
        height: "19px",
        position: "relative",
        top: "3px",
        paddingRight: "2px",
    },
    securityContainer: {
        paddingBottom: "10px",
    },
});

const UserInputField: FC<InputProps> = ({ onSend, isLoading, stopResponding, showChatBot }) => {
    const images = (require as any).context("./../../assets", true);
    const iconPath = "./bot.png";
    const dataProtectionImg = "./DataProtection.png";
    const botIcon = images(iconPath);
    const dataProtectionIcon = images(dataProtectionImg);
    const [message, setMessage] = useState("");
    const maxLength = 2000;
    const styles = useStyles();
    const { t, i18n } = useTranslation();

    const handleMessageChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const newMessage = event.target.value;
        if (newMessage.length <= maxLength) {
            setMessage(newMessage);
        }
    };

    const parseInput = (message: string) => {
        return message
            .replace(/non profit/ig, 'Nonprofit');
        //we can include any other parsing needs here later
    }

    const removeHtmlTags = (str: string) => {
        return str.replace(/<[^>]*>(.*?)<\/[^>]*>|\/(.|\n)+\/[gimuy]*/ig, '');
    }

    const sendMessage = async (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        const parsedMessage = parseInput(message);
        var sanitizedMessage = removeHtmlTags(parsedMessage);

        sanitizedMessage = sanitizedMessage.replace(/<[^>]+>/, ""); // Regular expression that matches HTML tags
        onSend(sanitizedMessage);
        setMessage("");
    };
    const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
        const clipboardData = event.clipboardData.getData("Text");
        const resultingLength = message.length + clipboardData.length;

        if (resultingLength > maxLength) {
            event.preventDefault();
            const allowedText = clipboardData.slice(0, maxLength - message.length);
            setMessage((prev) => prev + allowedText);
        }
    };


    return (
        <>
            <Stack>
                <Stack horizontal horizontalAlign="end" className={styles.securityContainer}>

                    <span className={styles.securityText}>
                    {t('ChatBot.AIWarning')}
                    </span>
                </Stack>
                <Stack horizontal>

                    <span className="bot-input">
                        <form onSubmit={sendMessage}>
                            <Field>
                                <Textarea
                                    disabled={!showChatBot}
                                    className="input-area"
                                    placeholder={t('ChatBot.AskQuestion')}
                                    resize="none"
                                    size="large"
                                    value={message}
                                    onChange={handleMessageChange}
                                    onPaste={handlePaste}
                                    onKeyUp={(event) => {
                                        if (event.key === "Enter" && !event.shiftKey && message.trim() !== "" && message.length <= 2000 && !isLoading && !stopResponding) {
                                            event.preventDefault();
                                            sendMessage();
                                        }

                                    }}
                                />
                                <span style={{ textAlign: "right", color: "#767676" }}>
                                    <span>{message.length}/2000</span>
                                    <IconButton
                                        type="submit"
                                        ariaLabel="Send"
                                        iconProps={{ iconName: "Send" }}
                                        className="send-button"
                                        disabled={message.trim() === "" || message.length > 2000 || (isLoading && !stopResponding)}
                                        style={{
                                            backgroundColor:
                                                message.trim() === "" ? "transparent" : "",
                                        }}
                                    />
                                </span>
                            </Field>
                        </form>
                    </span>
                </Stack>
            </Stack>
        </>
    );
};
export default UserInputField;

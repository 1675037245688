import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    makeStyles,
} from "@fluentui/react-components";
import { FC, useEffect, useRef, useState } from "react";
import CancelIcon from "./CancelIcon";
import Messages from "./Messages";
import UserMessage from "./UserMessage";
import BotMessage from "./BotMessage";
import UserInputField from "./UserInputField";
import { Prompt, PromptWithHistory } from "../../Types/PromptType";
import { getCannedResponses, getChatResponse, loadPromptAndResponse } from "../../services/ChatbotService";
import FollowUpQuestions from "./FollowUpQuestions";
import { Feedback, PromptDetails, ResponseWithFeedback, UserIpDetails } from "../../Types/FeedbackType";
import axios from "axios";
import { FormQuestions } from "../../Types/FormQuestions";
import SkillsPlanForm from "./SkillsPlanForm";
import { useCannedQuestions, useSupportedLanguages } from "../../hooks/useHooks";
import BotLearningCard from "../Cards/BotLearningCard";
import { LearningType } from "../../Types/LearningType";
import { generateGUID } from "../../utilities/GuidGenerator";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { DefaultButton } from "@fluentui/react";
import { sentFeedback } from "../../services/ChatbotService";
import { getLanguageCodeFromUrl } from "../../utilities/LocalizationHelper";
import Warnings from "./Warnings";

interface panelProp {
    botAction?: string;
    initialText?: string;
    isOpen: boolean;
    togglePanel: () => void;
    setBotAction: (action: string) => void;
    setinitialText: (text: string) => void;
    isLoadingData: boolean;
    setIsLoading: (loading: boolean) => void;
}
interface SupportedLanguage {
    Locale: string;
    EnglishName: string;
    LocalizedName: string;
    ShowChatBot: boolean;
}
const useStyles = makeStyles({
    panelBody: {
        paddingTop: "19px",
        paddingRight: "19px",
        backgroundColor: "#F7F7F7",

    },
    panelFooter: {
        backgroundColor: "#F7F7F7",
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "13px",
        paddingBottom: "13px",
        display: "inline !important"
    },

});
var currentTime: Date;
let followUpCounter = 0;
let globalCounter = 0;
let errorCounter = 0;
let timeDifferenceInMinutes = 0;
let userPromptLength = 0;
let supportedLanguages: SupportedLanguage[] = []; 

const ChatBotPanel: FC<panelProp> = (prop) => {
    const el = useRef<HTMLDivElement>(null);
    const [showChatBot, setShowChatBot] = useState(true);
    const { isOpen, togglePanel, botAction, setBotAction, setinitialText, isLoadingData, setIsLoading } = prop;
    const { data, isLoading, error } = useCannedQuestions();
    const { data: supportedLanguagesData, isLoading: isLoadingSupportedLanguages, error: errorSupportedLanguages } = useSupportedLanguages();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    // const [isLoading, setIsLoading] = useState(false);
    const [formAnswers, setFormAnswers] = useState<string[]>([]);
    const [cannedResponse, setCannedResponse] = useState<LearningType[]>([]);
    const [formQuestions, setFormQuestions] = useState<FormQuestions>(
        {
            id: "",
            question: "",
            options: [],
            timeStamp: "",
            questionOrder: 0
        }
    );

    const [apiResponse, setApiResponse] = useState<ResponseWithFeedback>({ id: "", response: "", feedback: "", timeStamp: "" });
    const maxMessages = 10;
    const [messages, setMessages] = useState<React.ReactNode[]>([]);
    const [followUps, setFollowUps] = useState<FormQuestions>(
        {
            id: "",
            question: "",
            options: [],
            timeStamp: "",
            questionOrder: 0
        }
    );

    const [stopResponding, setStopResponding] = useState(false);
    const [messagesCleared, setMessagesCleared] = useState(false);
    const [isGreetingMessage, setisGreetingMessage] = useState(false);
    const [userPrompt, setuserPrompt] = useState<Prompt>({
        prompt: "",
        history: [],
        time: new Date(),
        isNewSession: false,
        followUpCount: 0
    });
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [feedbackdata, setfeedbackdata] = useState<Feedback | null>(null);
    const [modalSubmitCallback, setModalSubmitCallback] = useState<((updatedFeedback: Feedback) => void) | null>(null);

    const openModal = (feedbackData: Feedback, onSubmit: (updatedFeedback: Feedback) => void) => {
        setfeedbackdata(feedbackData);
        setModalOpen(true);
        setModalSubmitCallback(() => onSubmit);  // Store the callback function
    };

    const closeModal = () => {
        setModalOpen(false);
        setfeedbackdata(null);
    };

    const navigate = useNavigate();

    useEffect(() => {
        if (error) {
            // Navigate to the error page
            navigate('/error');
        }
    }, [error, navigate]);

    useEffect(() => {
        if (el.current) {
            el.current.scrollIntoView({ block: "end", behavior: "auto" });
        }
    }, [messages]);
    useEffect(() => {
        try {
            if (!localStorage.getItem('supportedLanguages')) {                
                supportedLanguages = supportedLanguagesData.SupportedLanguages;
                localStorage.setItem('supportedLanguages', JSON.stringify(supportedLanguages));
            } else {
                supportedLanguages = JSON.parse(localStorage.getItem('supportedLanguages') || '[]');
            }
        } catch (error) {
            console.error('Failed to fetch supported languages:', error);
            if (window.location.pathname !== '/error') {
                window.location.href = '/error';
            }
        }
        const languageCode = getLanguageCodeFromUrl(window.location.href); 
        const supportedLanguage = supportedLanguages?.find(item => item.Locale.toLowerCase() === languageCode.toLowerCase());
        if (supportedLanguage){
            setShowChatBot(supportedLanguage.ShowChatBot);
           
                if (prop.botAction === "freeResponse" || prop.botAction === "SkillsPlan") {
                    setModalOpen(false);
                    let responseText = "";

                    if (prop.botAction === "freeResponse") {
                        if (supportedLanguage.ShowChatBot) {
                            if (!isGreetingMessage) {
                                responseText = `${t('AIAssistantIntro')}`;
                                setMessages(prevMessages => [...prevMessages, <BotMessage
                                    messageWithFeedback={{
                                        id: "greeting-id",
                                        response: responseText,
                                        feedback: "",
                                        timeStamp: formatTimestamp(),
                                    }}
                                    key="greeting-id"
                                    userPrompt={userPrompt}
                                    openModal={openModal}
                                />])
                                setisGreetingMessage(true);
                            }

                            if (prop.initialText) {
                                send(prop.initialText);
                            }
                        } else {
                            if (!isGreetingMessage) {
                                setMessages(prevMessages => [...prevMessages, <Warnings text={t('ChatBot.ComingSoon')}

                                />])
                                setisGreetingMessage(true);
                            }
                        }
                    } else if (prop.botAction === "SkillsPlan") {
                        setMessages([]);
                        setMessagesCleared(true);
                        setisGreetingMessage(false);

                    }
                }           
        }           
    }, [prop.botAction, prop.initialText]);

    useEffect(() => {
        if (messagesCleared && messages.length === 0) {
            const responseText = `<div class="plan-title">${t('ChatBot.SkillsPath')}</div><div class="plan-sub">${t('ChatBot.PersonalizedResources')}</div>`;
            const id = generateGUID();

            setMessages(prevMessages => [...prevMessages, <BotMessage
                messageWithFeedback={{
                    id: "Skill-id",
                    response: responseText,
                    feedback: "",
                    timeStamp: formatTimestamp(),
                }}
                key={id}
                userPrompt={userPrompt}
                openModal={openModal}
            />])
            setFormAnswers([]);
            onFormQuestionUpdate([], 0);
            setMessagesCleared(false);
        }
    }, [messagesCleared]);

    useEffect(() => {

        if (formQuestions?.question && formQuestions?.options.length > 0) {

            setMessages(prevMessages => [...prevMessages, <SkillsPlanForm key={formQuestions.id} options={formQuestions} onSendForm={onSendForm} />]);
        }

    }, [formQuestions?.id]);

    useEffect(() => {

        if (followUps?.question && followUps?.options.length > 0) {
            const newMessages = [
                ...messages,
                <FollowUpQuestions key={followUps.id} options={followUps} onSend={send} />,
            ];
            setMessages(newMessages);
        }
    }, [followUps?.id]);

    useEffect(() => {

        if (apiResponse?.response) {
            const newMessages = [
                ...messages,
                <BotMessage
                    messageWithFeedback={apiResponse}
                    key={apiResponse.id}
                    userPrompt={userPrompt}
                    openModal={openModal}
                />,
            ];
            setMessages(newMessages);

        }
    }, [apiResponse?.id]);

    const isEnglish = (text: string) => {
        return /^[a-zA-Z0-9\s?!.,@#$%&*+=_/'";:()-]*$/.test(text);
    };

    const onFormQuestionUpdate = (newFormAnswers: string[], questionIndex: number) => {
        if (data && !isLoading) {
            const filteredData = data?.filter((item: any, index: number) => index === questionIndex);

            if (filteredData.length > 0) {
                const selectedQuestion = filteredData[0];
                let id = getIdForFormQuestions();

                if (questionIndex === 2 && newFormAnswers.length === 2) {
                    const filteredOptions: string[] = selectedQuestion.options
                        ?.filter((p: any) => p.leverageExpectation === newFormAnswers[0] && p.experienceLevel === newFormAnswers[1])
                        .map((p: any) => p.learningTarget);

                    setFormQuestions({
                        id: id,
                        question: selectedQuestion.question,
                        options: Array.from(new Set(filteredOptions)),
                        timeStamp: formatTimestamp(),
                        questionOrder: questionIndex
                    });
                }
                else if (questionIndex === 1 && newFormAnswers.length === 1) {
                    const filteredOptions = selectedQuestion.options
                        ?.filter((p: any) => p.leverageExpectation === newFormAnswers[0])
                        .map((p: any) => p.experienceLevel);
                    setFormQuestions({
                        id: id,
                        question: selectedQuestion.question,
                        options: Array.from(new Set(filteredOptions)),
                        timeStamp: formatTimestamp(),
                        questionOrder: questionIndex
                    });
                }
                else {
                    setFormQuestions({
                        id: id,
                        question: selectedQuestion.question,
                        options: selectedQuestion.options,
                        timeStamp: formatTimestamp(),
                        questionOrder: questionIndex
                    });
                }
            }
        } else {
            let errorId = generateGUID();
            setMessages(prevMessages => [...prevMessages, <BotMessage
                messageWithFeedback={{
                    id: `errorSkillsPlan-${errorId}`,
                    response: `${t('ChatBot.SkillsPathError')}`,
                    feedback: "",
                    timeStamp: formatTimestamp(),
                }}
                key="errorSkillsPlan"
                userPrompt={userPrompt}
                openModal={openModal}
            />])
            setBotAction("");
        }
    }
    const onFollowUpsUpdate = (newFollowUps: string[]) => {
        let id = getIdForFollowUp();
        setFollowUps(
            {
                id: id,
                question: `${t('ChatBot.followUpSelect')}`,
                options: newFollowUps,
                timeStamp: formatTimestamp(),
                questionOrder: 0
            }

        );
    };

    const fetchIpInfo = async () => {
        try {
            const response = await axios.get('https://ipinfo.io/json');
            return response.data;

        } catch (error) {
            console.error('Error fetching IP info:', error);
        }
    };

    const savePromptAndResponse = async (prompt: string, response: string) => {
        const ip = await fetchIpInfo();
        const userIpInfo: UserIpDetails = {
            ip: ip.ip,
            country: ip.country,
            region: ip.region,
            city: ip.city,
            location: ip.loc
        }
        const data: PromptDetails = {
            prompt: prompt,
            response: response,
            UserIpAddress: userIpInfo
        };
        try {
            if (prompt && response) {
                const res = await loadPromptAndResponse(data);
            }
        } catch (error) {
            throw error;
        }
    }

    const stopBotResponding = () => {
        setApiResponse({ id: "", response: "", feedback: "", timeStamp: formatTimestamp() });
        setStopResponding(true);
    }

    const getIdForFormQuestions = () => {
        globalCounter += 1;
        return `unique-id-${globalCounter}`;
    };

    const getIdForFollowUp = () => {
        globalCounter += 1;
        return `unique-followUpId-${globalCounter}`;
    };

    const getIdForError = () => {
        errorCounter += 1;
        return `unique-errorId-${errorCounter}`;
    };

    const isUrlInBraces = (word: string) => {
        const urlPatterns = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;
        return word.replace(urlPatterns, '<a href="$2" target="_blank">$1</a>');
    }

    const isUrl = (word: string) => {
        const urlPattern = /^((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|aka.)?(\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?)$/gm;
        return word.match(urlPattern)
    }

    const addMarkup = (word: string) => {

        let finalWord = word;
        let char = '';

        if (isUrl(word)) {
            if (word.endsWith('.')) {
                finalWord = word.slice(0, -1);
                char = '.';
            } else
                if (word.endsWith(',')) {
                    finalWord = word.slice(0, -1);
                    char = ',';
                } else
                    if (word.endsWith('-')) {
                        finalWord = word.slice(0, -1);
                        char = '-';
                    }

            const url = finalWord.startsWith('http') ? finalWord : finalWord.startsWith('//') ? `https:${finalWord}` : `https://${finalWord}`;
            return `<a href="${url}" target="_blank">${finalWord}</a> ${char}`;
        } else {
            return word;
        }
    }

    const isUrlInBracesCheck = (text: string) => {
        const urlPattern = /[\(\[\{]((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?(\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?)[\)\]\}]/gm;
        const bracketPattern = /(\[[^\]]*\])|(\([^\)]*\))|({[^}]*})/g;
        const words = text.split(" ");

        const formattedWords = words.map((word) => {
            let formattedWord = word;
            if (bracketPattern.test(word)) {
                const url = word.match(urlPattern);
                if (url) {
                    let link = url[0];
                    link = link.startsWith('//') ? `https:${link}` : link;
                    formattedWord = word.replace(
                        link,
                        `<a href="${link}" target="_blank">${link}</a>`
                    );
                    formattedWord = formattedWord.replace(
                        bracketPattern,
                        (match, p1, p2, p3) => {
                            if (p1) return p1.slice(1, -1);
                            if (p2) return p2.slice(1, -1);
                            if (p3) return p3.slice(1, -1);
                        }
                    );
                }
            }

            // formattedWord = formattedWord.replace(newlinePattern, " <br> ");
            return formattedWord;
        });

        return formattedWords.join(" ");
    };

    const removeHtmlTags = (text: string) => {
        //removing html tags to save data to db
        const anchorPattern = /<a[^>]*>|<\/a>/gi;
        const brPattern = /<br\s*\/?>/gi;
        const whitespacePattern = /[\n\t\r]/g;

        let cleanedText = text.replace(anchorPattern, '');
        cleanedText = cleanedText.replace(brPattern, '');
        cleanedText = cleanedText.replace(whitespacePattern, '');

        return cleanedText;
    };

    const sendResponse = async (updatedPrompt: Prompt, text: string, isFollowUpClicked?: boolean) => {
        let errorId;
        setStopResponding(false);
        const englishOnlyArray = updatedPrompt.history.filter((entry) => isEnglish(entry));
        updatedPrompt.history = englishOnlyArray;
        await setuserPrompt(updatedPrompt);
        const containsAlphanumeric = /^[^a-zA-Z]+$/;
        const containsAtLeastOneLetter = /\p{L}/u;
        if (containsAlphanumeric.test(text) && !containsAtLeastOneLetter.test(text) && text !== '\n') {

            errorId = getIdForError();
            setApiResponse({ id: errorId, response: `${t('ChatBot.ChatBotError')}`, feedback: "", timeStamp: formatTimestamp() });
            text = "";
        } else if (text.length > 1) {
            // setIsLoading(true);
            //      if (!isEnglish(text)) {
            //         var id = getIdForNonEnglishInput();
            //        setApiResponse({ id: id, response: "Language other than English US is not supported at this time. Thank you!", feedback: "" });
            //        setIsLoading(false);
            //   } else {
            try {
                setIsLoading(true);
                if (isFollowUpClicked) {
                    followUpCounter = followUpCounter + 1
                } else {
                    followUpCounter = 0;
                }

                const updatedPrompts: PromptWithHistory = {
                    prompt: updatedPrompt.prompt,
                    history: updatedPrompt.history,
                    followUpCount: followUpCounter
                };
                const chatResponse = await getChatResponse(updatedPrompts);
                if (!chatResponse.message.startsWith("I'm sorry") && !chatResponse.message.startsWith("Hello, I am not sure of the answer to your question.")
                    && !chatResponse.message.startsWith("The requested information") && !chatResponse.message.startsWith("We apologize for the inconvenience,")
                    && !chatResponse.message.startsWith("Microsoft Learn is your trusted source to help you get skilled up")) { // Do not add question without answer to history array.
                    updatedPrompt.history = [...updatedPrompt.history, updatedPrompt.prompt];
                    await setuserPrompt(updatedPrompt);
                }
                const pattern = /\*|#|\[\w+\]|\(doc\d+\)/g;
                var botResponse = chatResponse.message.replace(pattern, '');
                botResponse = botResponse.replace(/\[\s*doc\d+(\s*,\s*doc\d+)*\s*\]/g, "");
                var res = botResponse.replace(/\n/g, '\n ');
                const words = res.split(' ')
                const formatedWords = words.map((w, i) => addMarkup(w))
                const formattedBotResponse = formatedWords.join(' ')
                botResponse = formattedBotResponse;
                var formattedMsg = isUrlInBracesCheck(botResponse);
                formattedMsg = formattedMsg.replace(/\n\s*\n\s*(?=<a href)/g, "\n ");
                formattedMsg = formattedMsg.replace(/\n/g, " <br> ");
                formattedMsg = formattedMsg.replace(/(\[|\]|\(|\)|\{|\})/g, "");
                formattedMsg = formattedMsg.replace(/(?<! )<a href/g, " <a href");

                var finalMsg = removeSpaceBeforeFullStop(formattedMsg);
                setMessages(prevMessages => [...prevMessages, <BotMessage
                    messageWithFeedback={{ id: chatResponse.id, response: finalMsg, feedback: "", timeStamp: formatTimestamp() }}
                    key={chatResponse.id}
                    userPrompt={updatedPrompt}
                    openModal={openModal}
                />]);
                // setApiResponse({ id: chatResponse.id, response: finalMsg, feedback: "", timeStamp: formatTimestamp() });

                savePromptAndResponse(updatedPrompt.prompt, removeHtmlTags(finalMsg));

                //setuserPrompt((prevState: Prompt) => ({
                //    ...prevState,
                //    prompt: prevState.prompt,
                //    history: [...prevState.history, botResponse],
                if (userPromptLength < maxMessages) {
                    onFollowUpsUpdate(chatResponse.followUps);
                }
                //}));
                setBotAction("");
                setinitialText("");
            } catch (error) {
                errorId = getIdForError();
                setApiResponse({ id: errorId, response: `${t('ChatBot.ChatBotErrorFetching')}`, feedback: "", timeStamp: formatTimestamp() });
            } finally {
                setIsLoading(false);
                // setStopResponding(false);
            }
        } else {
            errorId = getIdForError();
            setApiResponse({ id:'MaliciousPrompt-'+ errorId, response: `${t('ChatBot.MaliciousPrompt')}`, feedback: "", timeStamp: formatTimestamp() });
        }
    }

    const removeSpecialCharacters = (text: string) => {
        return text.replace(/[<>\/|\\.,:;~`\"'{}\[\]()=*+&^%#!_-]|\u2019/g, "");
    }

    const removeSpaceBeforeFullStop = (text: string) => {
        return text.replace(/\s+(\.)/g, '$1');
    };

    // Function to format the current timestamp
    const formatTimestamp = () => {
        const now = new Date();
        return now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const onResetChatbot = () => {
        setMessages([]);
        localStorage.clear();
        setuserPrompt({
            prompt: "",
            history: [],
            time: new Date(),
            isNewSession: false,
            followUpCount: 0
        });
        setApiResponse({ id: "", response: "", feedback: "", timeStamp: formatTimestamp() });
        setFollowUps({
            id: "followup-reset-id", question: "",
            options: [],
            timeStamp: "",
            questionOrder: 0
        });
        setStopResponding(false);
        setIsLoading(false);
        globalCounter = 0;
        errorCounter = 0;
        timeDifferenceInMinutes = 0;
        userPromptLength = 0;
        setFormAnswers([]);
        setCannedResponse([]);
        setBotAction("");
        setinitialText('');
        setisGreetingMessage(false);
        setSelectedCategory('');
        setfeedbackdata(null);
        setAdditionalInfo('');
        setModalOpen(false);

    };

    const onSendForm = async (text: any, questionOrder: number) => {
        setMessages(prevMessages => [...prevMessages, <UserMessage key={messages.length + 1} text={text} timeStamp={formatTimestamp()} />]);
        const order = questionOrder + 1;
        const newFormAnswers = [...formAnswers, text];
        setFormAnswers(newFormAnswers);
        onFormQuestionUpdate(newFormAnswers, order);
    }

    const getCannedResponse = async (formAnswers: string[]) => {
        setIsLoading(true);
        try {
            const cannedResponses = await getCannedResponses(formAnswers);
            if (cannedResponses.length > 0) {
                const id = generateGUID();
                setMessages(prevMessages => [
                    ...prevMessages, <BotMessage
                        messageWithFeedback={{
                            id: 'LearningPath'+ id,
                            response: `<div>${t('ChatBot.LearningPath')}</div>`,
                            feedback: "",
                            timeStamp: " ",
                        }}
                        key={id}
                        userPrompt={userPrompt}
                        openModal={openModal}
                    />])
                //  setApiResponse({ id: generateGUID(), response: `<div>Here are a few learning paths designed to help you:</div>`, feedback: "", timeStamp: "" });
                setCannedResponse(cannedResponses);
            } else {
                const id = generateGUID();

                setMessages(prevMessages => [
                    ...prevMessages, <BotMessage
                        messageWithFeedback={{
                            id: 'NoLearningPath'+ id,
                            response: `<div>${t('ChatBot.NoLearningPath')}</div>`,
                            feedback: "",
                            timeStamp: formatTimestamp(),
                        }}
                        key={id}
                        userPrompt={userPrompt}
                        openModal={openModal}
                    />])
                // setApiResponse({ id: generateGUID(), response: `<div>There are no learning paths to show at this moment.</div>`, feedback: "", timeStamp: formatTimestamp() });
            }
        } catch (error) {
            const id = generateGUID();
            setMessages(prevMessages => [
                ...prevMessages, <BotMessage
                    messageWithFeedback={{
                        id: 'NoLearningPath' + id,
                        response: `<div>${t('ChatBot.NoLearningPath')}</div>`,
                        feedback: "",
                        timeStamp: formatTimestamp(),
                    }}
                    key={id}
                    userPrompt={userPrompt}
                    openModal={openModal}
                />])
            //  setApiResponse({ id: generateGUID(), response: `<div>There are no learning paths to show at this moment.</div>`, feedback: "", timeStamp: formatTimestamp() });
            console.error("Error fetching canned response:", error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        const fetchCannedResponse = async () => {
            if (formAnswers?.length === 3) {
                await getCannedResponse(formAnswers);
                setBotAction("");
            }
        };

        fetchCannedResponse();
    }, [formAnswers]);

    useEffect(() => {
        if (cannedResponse && cannedResponse.length > 0) {
            const newMessages = [
                ...messages,
                ...cannedResponse.map((response, index) => (
                    <BotLearningCard key={messages.length + index + 1} cardData={response} />
                )),
            ];
            setMessages(newMessages);
        }
    }, [cannedResponse]);

    const send = async (text: any, isFollowUpClicked?: boolean) => {
        const newlinePattern = /\n/g;
        text = text.replace(newlinePattern, '');
        setFollowUps({
            id: "",
            question: "",
            options: [],
            timeStamp: "",
            questionOrder: 0
        });
        userPromptLength = userPromptLength + 1;
        setStopResponding(false);
        //setApiResponse({ id: "", response: "", feedback: "", timeStamp: formatTimestamp() });
        //  let newMessages = [...messages];
        var formattedPrompt = removeSpecialCharacters(text);
        const updatedPrompt = {
            ...userPrompt,
            prompt: formattedPrompt,
            //  history: [...userPrompt.history, formattedPrompt],
            time: new Date(),
            followUpCount: 0
        };

        if (userPromptLength === 1) {
            currentTime = new Date();

        }
        const timeDifference = Math.abs(updatedPrompt.time.getTime() - currentTime.getTime());
        timeDifferenceInMinutes = Math.floor((timeDifference / 1000) / 60);
        if ((messages && userPromptLength > maxMessages) && timeDifferenceInMinutes <= 15) {
            const newMessages = [
                ...messages,
                <UserMessage key={messages.length + 1} text={t('ChatBot.MaximumLimit')} timeStamp={formatTimestamp()} />
            ];

            setMessages(newMessages);
        } else if (messages && (userPromptLength <= maxMessages || userPromptLength > maxMessages) && timeDifferenceInMinutes > 15) {

            onResetChatbot();

            const newMessagesArray: React.ReactNode[] = [];
            //  setMessages(newMessages);

            const updatedPromptNew = {
                prompt: formattedPrompt,
                history: [formattedPrompt],
                time: new Date(),
                isNewSession: true,
                followUpCount: 0
            };
            currentTime = new Date();
            userPromptLength = 1;
            //  newMessagesArray.push(<UserMessage key={newMessagesArray.length + 1} text={text} timeStamp={formatTimestamp()} />);
            setMessages(prevMessages => [...prevMessages, <UserMessage key={newMessagesArray.length + 1} text={text} timeStamp={formatTimestamp()} />]);
            sendResponse(updatedPromptNew, text, isFollowUpClicked);

        } else {
            setMessages(prevMessages => [...prevMessages, <UserMessage key={messages.length + 1} text={text} timeStamp={formatTimestamp()} />]);

            sendResponse(updatedPrompt, text, isFollowUpClicked);
        }
    };
    const isSubmitDisabled = selectedCategory === '';

    const handleSubmit = async () => {
        setModalOpen(false);
        const updatedFeedback: Feedback = {
            prompt: feedbackdata?.prompt || '',
            response: feedbackdata?.response || '',
            feedback: feedbackdata?.feedback || '',
            UserIpAddress: feedbackdata?.UserIpAddress || { ip: '', country: '', region: '', city: '', location: '' },
            AdditionalInfo: additionalInfo,
            Category: selectedCategory,
        };
        if (updatedFeedback) {
            const res = await sentFeedback(updatedFeedback);
            if (modalSubmitCallback) {
                modalSubmitCallback(updatedFeedback); // Pass the updated feedback data to the callback
            }
        }

        setSelectedCategory('');
        setfeedbackdata(null);
        setAdditionalInfo('');

    };

    return (
        <div>
            <Drawer
                modalType="non-modal"
                open={isOpen}
                position="end"
                style={{ width: "435px", color: "#F4F4F4", borderRadius: "0px 0px 25px 25px", right: "" }}
            >
                <CancelIcon togglePanel={togglePanel} />
                <DrawerBody className={styles.panelBody}>
                    {/*   <PanelHeader />*/}
                    {/* <QueryGuide />*/}
                    <Messages
                        botAction={botAction}
                        messages={messages}
                        isLoading={isLoadingData}
                        userPrompt={userPrompt}
                        stopResponding={stopResponding}
                        stopBotResponding={stopBotResponding}
                    />

                    {/*{followUps &&*/}
                    {/* !isLoading &&*/}
                    {/* !stopResponding &&*/}
                    {/*            messages && userPromptLength < maxMessages ? (*/}
                    {/*  <FollowUpQuestions key="0" questions={followUps} onSend={send} />*/}
                    {/*) : (*/}
                    {/*  ""*/}
                    {/*      )} */}
                    <div id={"el"} ref={el} />
                </DrawerBody>
                <DrawerFooter className={styles.panelFooter}>
                    <UserInputField onSend={send} isLoading={isLoadingData} stopResponding={stopResponding} showChatBot={showChatBot } />
                </DrawerFooter>
                {isModalOpen && (
                    <div className="modal-overlay" onClick={closeModal}>
                        <div className="modal-content" onClick={e => e.stopPropagation()}>
                            <div className="modal-header">
                                <button className="close-button" onClick={closeModal}>X</button>
                            </div>
                            <h2>{t('Feedback.heading','We are sorry to hear that!')}</h2>
                            <p>{t('Feedback.subHeading','Please select a reason for your feedback:')}</p>

                            <div>
                            <label style={{ minWidth: '100px', display: 'inline-block' }}>
                                    <input
                                        type="radio"
                                        value="Incorrect"
                                        checked={selectedCategory === "Incorrect"}
                                        onChange={(e) => setSelectedCategory(e.target.value)}
                                    />
                                      {t('Feedback.RadioButton.0.CheckboxText','Incorrect')}  
                                </label>
                                <label style={{ minWidth: '100px', display: 'inline-block' }}>
                                    <input
                                        type="radio"
                                        value="Incomplete"
                                        checked={selectedCategory === "Incomplete"}
                                        onChange={(e) => setSelectedCategory(e.target.value)}
                                    />
                                    {t('Feedback.RadioButton.1.CheckboxText','Incomplete')}  
                                </label>
                                <label style={{ minWidth: '100px', display: 'inline-block' }}>
                                    <input
                                        type="radio"
                                        value="Inappropriate/Harmful"
                                        checked={selectedCategory === "Inappropriate/Harmful"}
                                        onChange={(e) => setSelectedCategory(e.target.value)}
                                    />
                                     {t('Feedback.RadioButton.2.CheckboxText','Inappropriate/Harmful')}
                                </label>
                            </div>
                            <div style={{ paddingTop: '20px' }}>
                                <label htmlFor="additionalInfo"> {t('Feedback.AdditionalInformation','Additional Information:')}</label>
                                <textarea
                                    id="additionalInfo"
                                    className="textarea"
                                    value={additionalInfo}
                                    onChange={(e) => setAdditionalInfo(e.target.value)}
                                    maxLength={300}
                                    rows={4}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <DefaultButton
                                className={`feedback-button ${!isSubmitDisabled ? 'active' : ''}`}
                                onClick={() => handleSubmit()}
                                disabled={isSubmitDisabled}
                            >   
                             {t('Feedback.Submit','Submit')}
                                
                            </DefaultButton>
                        </div>
                    </div>
                )}
            </Drawer>

        </div>

    );
};

export default ChatBotPanel;

import i18n from "i18next";

// Function to convert languages to standardized i18next format 
const convertToI18nextLang = (lang: string): string => {
  const parts = lang.split('-');
  if (parts.length === 2) {
    return `${parts[0].toLowerCase()}-${parts[1].toUpperCase()}`;
  }
  return lang;
};


export const getLanguageCodeFromUrl = (url) => {
  try {
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname.split('/'); // Split by slashes
    // Assuming the language code is the first segment after the domain
    const languageCode = pathname[1] ? convertToI18nextLang(pathname[1]) : 'en-US';
    if (/^[a-z]{2}-[A-Z]{2}$/.test(languageCode)) {
      return languageCode;
    } else {
      const detectedLang = i18n.language || 'en-us';
      return detectedLang; // Default to 'en-US' if not found or invalid
    }
  } catch (error) {
    console.error("Invalid URL:", error);
    return 'en-us';
  }
};
import { useQuery } from "react-query";
import {
  getLatestBlogs,
  getDiscussionCategories,
  getLatestEvents,
  getDiscussionDetails,
  getUHF,
    getSpotlightInfo,
    getLearnByTopic,
    getLearnByRole,
    getSuccessStories,
    getCannedQuestions,
    getSupportedLanguages
} from "../services/LandingPageServices";
import {
  getCarouselData,
  getGroupInterstData as getInterstGroupData,
  getSpotlightConfig,
} from "../services/BlobServices";
import { getChatResponse } from "../services/ChatbotService";

export const useDiscussionCategories = () => {
  return useQuery("discussionCategories", getDiscussionCategories, {
    refetchOnWindowFocus: false,
  });
};

export const useSpotLightInfo = (messageIds: Array<string>) => {
  return useQuery("spotlightInfo", () => getSpotlightInfo(messageIds), {
    refetchOnWindowFocus: false,
    enabled: !!messageIds,
  });
};

export const useLatestBlogs = () => {
  return useQuery("latestBlogs", getLatestBlogs, {
    refetchOnWindowFocus: false,
  });
};

export const useUHF = () => {
  return useQuery("UFH", getUHF, { refetchOnWindowFocus: false });
};

export const useLatestEvents = () => {
  return useQuery("latestEvents", getLatestEvents, {
    refetchOnWindowFocus: false,
  });
};

export const useDiscussionDetails = (boardId: string, sortParam: string) => {
  return useQuery(
    ["discussionDetails", boardId, sortParam],
    () => getDiscussionDetails(boardId, sortParam),
    {
      refetchOnWindowFocus: false,
      enabled: !!boardId.length && !!sortParam.length,
    }
  );
};

export const useSpotlightConfig = () => {
  return useQuery(["spotlightDetails"], () => getSpotlightConfig(), {
    refetchOnWindowFocus: false,
  });
};

export const useCarouselData = () => {
  return useQuery(["carouselDetails"], () => getCarouselData(), {
    refetchOnWindowFocus: false,
  });
};

export const useInterstGroupData = () => {
  return useQuery(["interestGroupDetails"], () => getInterstGroupData(), {
    refetchOnWindowFocus: false,
  });
};
export const useLearnByTopic = () => {
    return useQuery("LearnByTopic", getLearnByTopic, {
        refetchOnWindowFocus: false,
    });
};

export const useLearnByRole = () => {
    return useQuery("LearnByRole", getLearnByRole, {
        refetchOnWindowFocus: false,
    });
};
export const useSuccessStories = () => {
    return useQuery("SuccessStories", getSuccessStories, {
        refetchOnWindowFocus: false,
    });
}

export const useCannedQuestions = () => {
    return useQuery("CannedQuestions", getCannedQuestions, {
        refetchOnWindowFocus: false,
    });
};
export const useSupportedLanguages = () => {
    return useQuery("SupportedLanguages", getSupportedLanguages, {
        refetchOnWindowFocus: false,
    });
};
